<template>
<section class="content">
  <div id="user-group-assign" class="box box-default box-component box-body">
    <blocking-loader :show="isWorking">
      <template v-if="workMode === 'saving'">Trwa zapisywanie. Proszę czekać</template>
      <template v-if="workMode === 'getting'">Trwa ładowanie. Proszę czekać</template>
    </blocking-loader>
    <div class="groups-header row">
      <div class="col-xs-3">
        <strong class="">Użytkownik:</strong>
      </div>
      <template v-for="group in groups">
        <div v-if="group.id !== null"
        :key="group.id"
        class="col-xs-3">
          <strong>Likwidacja {{group.groupName}}</strong>
        </div>
        <div
          v-else
          :key="group.id"
          class="col-xs-3"
        >
          <strong class="pull-right">{{formatGroupName(group.groupName)}}</strong>
        </div>
      </template>
    </div>
    <div class="users-list list-group">
      <template v-for="user in assignedUsersToShow">
        <div
          :key="user.uuid"
          class="user row list-group-item">
          <div class="user-fullname col-xs-3"><strong>{{user.fullname}}</strong></div>
          <template v-for="group in groups">
            <div
              v-if="group.id !== null"
              :key="`checkbox-${group.id}`"
              class="col-xs-3">
              <input
                :id="`input-checkbox-${group.id}-${user.uuid}`"
                type="checkbox"
                :checked="user.groupIds.includes(group.id)"
                :value="user.groupIds.includes(group.id)"
                @click="(event) => assignUserToGroup({user, group, event})"
              >
              <label class="user-label" :for="`input-checkbox-${group.id}-${user.uuid}`"></label>
            </div>
            <div v-else :key="`reset-${group.id}-${user.uuid}`">
              <div class="resetButton col-xs-3">
                <button
                  class="zord-btn btn zord-btn-danger btn-danger zord-btn-trash btn-trash pull-right btn-icon"
                  @click="() => resetGroups(user)"
                ><i class="feather-trash" /></button>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</section>

</template>

<script>
  import api from '../../../../api'
  import BlockingLoader from '../../../share/BlockingLoader'
  export default {
    name: 'GroupPersonAssign',
    components: { BlockingLoader },
    data () {
      return {
        events: {
          save: 'intgen:group-person:save'
        },
        users: [],
        groups: [],
        assignedUsersToShow: [],
        assignedUsers: [],
        isWorking: false,
        workMode: ''
      }
    },
    async mounted () {
      this.workMode = 'getting'
      this.isWorking = true
      this.$events.on(this.events.save, this.putAssignedUsers)
      await this.getUsers(this.$route.meta.acl.service)
      await this.getGroups(this.$route.meta.acl.service)
      await this.getAssignedUsers(this.$route.meta.acl.service)
      this.isWorking = false
    },
    methods: {
      async getUsers (service) {
        try {
        let result = await api.request(service, 'get', '/roles/ROLE_LIKWIDATOR/users?format=selectOptions')
          this.users = result.data
      } catch (e) {
        console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać listy użytkowników.'
          })
      }
      },
      async getGroups (service) {
        try {
          let result = await api.request(service, 'get', '/task/type/group')
          result.data.forEach(group => this.groups.push(group))
          this.groups.push({groupName: 'Usuń zaznaczenie', id: null})
        } catch (e) {
          console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać listy grup.'
          })
        }
      },
      async getAssignedUsers (service) {
        try {
        let result = await api.request(service, 'get', '/task/type/group-user')
          result.data.forEach(group => {
            group.users.forEach(groupUser => {
              let groupUserChanged = groupUser
              groupUserChanged.groupIds = [group.id]
              this.assignedUsers.find(user => user.uuid === groupUser.uuid) ? this.assignedUsers.find(user => user.uuid === groupUser.uuid).groupIds.push(group.id) : this.assignedUsers.push(groupUserChanged)
            })
          })

          this.assignedUsersToShow = this.users.map(user =>
            this.assignedUsers.find(assignedUser => assignedUser.uuid === user.id) ?
              this.assignedUsers.find(assignedUser => assignedUser.uuid === user.id)
              : {uuid: user.id, fullname: user.name, groupIds: [null]})

        } catch (e) {
          console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać listy przypisanych użytkowników.'
          })
        }
      },
      formatGroupName(name) {
        return name.split('').map((letter, index) => index === 0 ? letter.toUpperCase() : letter).join('')
      },
      assignUserToGroup ({user, group, event}) {
        this.assignedUsersToShow.map(assignedUser => {
          if (assignedUser.uuid !== user.uuid) {
            return assignedUser
          }
          if (assignedUser.groupIds.includes(group.id)) {
            assignedUser.groupIds = assignedUser.groupIds.filter(assignedGroup => assignedGroup !== group.id)
          } else {
            assignedUser.groupIds.push(group.id)
          }
          return assignedUser
        })
      },
      resetGroups (user) {
        this.assignedUsersToShow.map(assignedUser => {
          if (assignedUser.uuid !== user.uuid) {
            return assignedUser
          }
          assignedUser.groupIds = []
          return assignedUser
        })
      },
      prepareAssignedUsersToSend () {
        return this.groups.map(group => {
          return {
            id: group.id,
            users: this.assignedUsersToShow.filter(user => user.groupIds.includes(group.id)).map(user => {
              return {uuid: user.uuid}
            })
          }
        }).filter(group => group.id !== null)
      },
      async putAssignedUsers () {
        this.workMode = 'saving'
        this.isWorking = true
        let data = this.prepareAssignedUsersToSend()
        try {
          await api.request(this.$route.meta.acl.service, 'put', '/task/type/group-user', data)
          await this.getAssignedUsers(this.$route.meta.acl.service)
          this.isWorking = false
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        } catch (e) {
          this.isWorking = false
          console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można zapisać konfiguracji'
          })
        }
      }
    }
  }
</script>

<style scoped>
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.groups-header {
  padding: 2rem;
}

.box {
  border: 1px solid #e7e7e7;
  padding: 2rem;
}

.list-group-item {
  border: none;
  border-top: 1px solid #ddd;
}

.user {
  height: 4rem;
}

.user-fullname {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-label {
  margin-left: 4rem;
}

.resetButton .btn.btn-danger {
  margin: 6px 50px;
  height: 25px;
  width: 25px;
  position: relative;
  top: -10px;
}
</style>
